@charset "UTF-8";
@import url(./font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400|Passion+One:400");



/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	

		body.is-loading *, body.is-loading *:before, body.is-loading *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}


.navbar-nav {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none !important;
	border-bottom: none !important;
}


.navbar-nav > a {
	margin-right: 20px;
	margin-bottom: 5px;
}

.navbar-light .navbar-nav .nav-link  {
    text-decoration: none ;
	color: black;

 
}


.headinggaleri {
    text-align: center;
    padding-bottom: 20px;
    padding-top: 40px;
}

.searc-input {
	background-color: transparent !important;
	border: none;
}

.btn-default {
	background-color: white !important;
	width: 200px;
	text-align: justify;
}

.stylewith {
	width: 0 !important;
	text-align: left !important;
}
.headinggaleri2 {
    text-align: center;
	padding-bottom: 10px;
	margin-top: 20px;
    padding-top: 40px;
}

.headinggaleri2 > h1 {
    padding-bottom: 10px;
}

.headinggaleri2 p {
	font-size: 18px;
	padding-bottom: 0 !important;
	margin-bottom: 5px !important;
}

.headinggaleri p {
	font-size: 18px;
	padding-bottom: 0 !important;
	margin-bottom: 5px !important;
}

.buttongalery {
    text-align: center;
    padding-top: 30px;
	padding-bottom: 30px;
	

}

/* Type */

	

		@media screen and (max-width: 1680px) {

			body, input, select, textarea {
				font-size: 11pt;
			}

		}

		@media screen and (max-width: 1280px) {

			body, input, select, textarea {
				font-size: 11pt;
			}

		}

		@media screen and (max-width: 980px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 736px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 480px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

			.btn-default {
				width: 100% !important;
			}

			.searchinput {
				width: 100px !important;

			}

			.headinggaleri2 {
				padding-bottom: 10px !important;
			}

			.headinggaleri2 h1 {
				font-size: 35px;
			}
			.headinggaleri h1 {
				font-size: 35px;
			}
		

			

		}

	a {
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		color:#000;
		text-decoration: none;
		outline: 0;
	}

		a:hover {
			color: rgb(44, 42, 42);
			border: none;
			text-decoration: none;
		}

	strong, b {
		color: #fff;
		font-weight: 400;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
	
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
		}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.35em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px rgba(144, 144, 144, 0.25);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: rgba(144, 144, 144, 0.075);
		border-radius: 4px;
		border: solid 1px rgba(144, 144, 144, 0.25);
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 1px rgba(144, 144, 144, 0.25);
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header p {
		color: rgba(255, 255, 255, 0.5);
		position: relative;
		margin: 0 0 1.5em 0;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.8em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9em;
		margin-top: -0.6em;
	}

/* Box */

	.thumbnails {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-align-items: stretch;
		-webkit-align-items: stretch;
		-ms-align-items: stretch;
		align-items: stretch;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding-top: 20px;
	
	}

	.box {

	
		box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);


	}
	
	.box:hover {
		box-shadow:0 10px 20px rgba(0, 0, 0, 0.521);

	}



		.thumbnails .box {
			margin: 0 1em 2em 1em;
			width: 30%;
		}

			@media screen and (max-width: 1280px) {

				.thumbnails .box {
					width: 45%;
				}

			}

			@media screen and (max-width: 736px) {

				.thumbnails .box {
					width: 100%;
				}

			}
.color-black {
    color: black !important;
}

.color-white {
	color: rgba(245, 245, 245, 0.788) !important;
	
	
	
}
.color-white2 {
	color: rgb(206, 205, 205) !important;


}

.color-white2 > p {
	text-align: justify;
	font-size: 16px;
}

.style-about {
	padding-bottom: 40px;
}
.color-red {
    color: red !important;
}

.color-green {
    color: green !important;
}

.slide1{
    height: 600px;  /* your desired height for this slide */
    background: url(../img/banner.jpg) no-repeat center center;
    background-size: cover;
}

.slide2{
    height: 600px;  /* your desired height for this slide */
    background: url(../img/banner2.jpg) no-repeat center center;
    background-size: cover;
}

/* .carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 50px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
  } */
.captionhome {
	position: absolute;
	text-align: center;
	left: 36%;
	top: 35%;
}

.captionhome > img {
	height: 250px;

}

.captionhome2 {
	position: absolute;
	text-align: center;
	left: 38%;
	top: 23%;
}

.captionhome2 > img {
	height: 400px;

}

.text-justify {
	text-align: justify;
}

.text-center {
	text-align: center;
}


@media screen and (max-width: 768px) {

	.captionhome {
		position: absolute;
		text-align: center;
		left: 20%;
		top: 40%;
	}
	
	.captionhome > img {
		height: 150px;
	}
	.captionhome2 {
		position: absolute;
		text-align: center;
		left: 18%;
		top: 30%;
	}
	
	.captionhome2 > img {
		height: 270px;
	}
}
/* Box */

	.box {
		border-radius: 4px;
		margin-bottom: 2em;
		background: rgb(255, 255, 255);
		text-align: center;
		
	}

		.box > :last-child,
		.box > :last-child > :last-child,
		.box > :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		.box .image .fit {
			margin: 0;
		}

		.box .image img {
			border-radius: 4px 4px 0 0;
		}

		.box .inner {
			padding-left: 15px;
			padding-right: 15px;
			width: 100% !important;
		}

		.inner h3 {
			padding-top: 10px;
		}

		.innerbox p {
			height: 70px;
		}

		.btnstyle {
			margin-bottom: 20px !important;
		}
			@media screen and (max-width: 480px) {

				.box .inner {
					padding: 1em;
				}

				.btnstyle {
					margin-bottom: 5px !important;
				}

			}

		.box.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}

/* Icon */

	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-transform: none !important;
		}

		.icon > .label {
			display: none;
		}

/* Image */

	.image {
		border-radius: 4px;
		border: 0;
		display: inline-block;
		position: relative;
	}

	.imageabsolute {
		height: 50px;
		position: absolute;
		left: 45%;
		top: 40%;
	}

		.image img {
			border-radius: 4px;
			display: block;
		}

		.image.left, .image.right {
			max-width: 40%;
		}

			.image.left img, .image.right img {
				width: 100%;
			}

		.image.left {
			float: left;
			padding: 0 1.5em 1em 0;
			top: 0.25em;
		}

		.image.right {
			float: right;
			padding: 0 0 1em 1.5em;
			top: 0.25em;
		}

		.image.fit {
			display: block;
			margin: 0;
			width: 100%;
		}

			.image.fit img {
				width: 100%;
			}

		.image.main {
			display: block;
			margin: 0 0 3em 0;
			width: 100%;
		}

			.image.main img {
				width: 100%;
			}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px rgba(144, 144, 144, 0.25);
				padding: 0.5em 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

		ul.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;
		}

			ul.icons li {
				display: inline-block;
				text-align: center;
				padding-left: 0;
				padding-right: 1em;
			}

				ul.icons li a {
					display: block;
					border-radius: 100%;
					margin: 0;
					padding: 0.5em;
					border: solid 1px rgba(0, 0, 0, 0.993);
					color: rgb(0, 0, 0);
				}

					ul.icons li a:before {
						font-size: 1.5em;
						display: block;
						width: 1.5em;
						height: 1.5em;
						line-height: 1.5em;
					}

					ul.icons li a:hover {
						background-color: #202024;
						color: #FFF;
						text-decoration: none;
					}

				ul.icons li:last-child {
					padding-right: 0;
				}

		ul.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;
		}

			ul.actions li {
				display: inline-block;
				padding: 0 1em 0 0;
				vertical-align: middle;
			}

				ul.actions li:last-child {
					padding-right: 0;
				}

			ul.actions.small li {
				padding: 0 0.5em 0 0;
			}

			ul.actions.vertical li {
				display: block;
				padding: 1em 0 0 0;
			}

				ul.actions.vertical li:first-child {
					padding-top: 0;
				}

				ul.actions.vertical li > * {
					margin-bottom: 0;
				}

			ul.actions.vertical.small li {
				padding: 0.5em 0 0 0;
			}

				ul.actions.vertical.small li:first-child {
					padding-top: 0;
				}

			ul.actions.fit {
				display: table;
				margin-left: -1em;
				padding: 0;
				table-layout: fixed;
				width: calc(100% + 1em);
			}

				ul.actions.fit li {
					display: table-cell;
					padding: 0 0 0 1em;
				}

					ul.actions.fit li > * {
						margin-bottom: 0;
					}

				ul.actions.fit.small {
					margin-left: -0.5em;
					width: calc(100% + 0.5em);
				}

					ul.actions.fit.small li {
						padding: 0 0 0 0.5em;
					}

			@media screen and (max-width: 480px) {

				ul.actions {
					margin: 0 0 2em 0;
				}

					ul.actions li {
						padding: 1em 0 0 0;
						display: block;
						text-align: center;
						width: 100%;
					}

						ul.actions li:first-child {
							padding-top: 0;
						}

						ul.actions li > * {
							width: 100%;
							margin: 0 !important;
						}

							ul.actions li > *.icon:before {
								margin-left: -2em;
							}

					ul.actions.small li {
						padding: 0.5em 0 0 0;
					}

						ul.actions.small li:first-child {
							padding-top: 0;
						}

			}

	dl {
		margin: 0 0 2em 0;
	}

		dl dt {
			display: block;
			font-weight: 400;
			margin: 0 0 1em 0;
		}

		dl dd {
			margin-left: 2em;
		}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

		table tbody tr {
			border: solid 1px rgba(144, 144, 144, 0.25);
			border-left: 0;
			border-right: 0;
		}

			table tbody tr:nth-child(2n + 1) {
				background-color: rgba(144, 144, 144, 0.075);
			}

		table td {
			padding: 0.75em 0.75em;
		}

		table th {
			color: #000;
			font-size: 1.3em;
			font-weight: 700;
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		table thead {
			border-bottom: solid 2px rgba(144, 144, 144, 0.25);
		}

		table tfoot {
			border-top: solid 2px rgba(144, 144, 144, 0.25);
		}

		table.alt {
			border-collapse: separate;
		}

			table.alt tbody tr td {
				border: solid 1px rgba(144, 144, 144, 0.25);
				border-left-width: 0;
				border-top-width: 0;
			}

				table.alt tbody tr td:first-child {
					border-left-width: 1px;
				}

			table.alt tbody tr:first-child td {
				border-top-width: 1px;
			}

			table.alt thead {
				border-bottom: 0;
			}

			table.alt tfoot {
				border-top: 0;
			}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		background-color: #70c7be;
		border-radius: 4px;
		border: 0;
		color: #ffffff !important;
		cursor: pointer;
		display: inline-block;
		font-weight: 400;
		height: 2.85em;
		line-height: 2.95em;
		padding: 0 1.5em;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}

		input[type="submit"]:hover,
		input[type="reset"]:hover,
		input[type="button"]:hover,
		button:hover,
		.button:hover {
			background-color: #82cec6;
		}

		input[type="submit"]:active,
		input[type="reset"]:active,
		input[type="button"]:active,
		button:active,
		.button:active {
			background-color: #5ec0b6;
		}

		input[type="submit"].icon,
		input[type="reset"].icon,
		input[type="button"].icon,
		button.icon,
		.button.icon {
			padding-left: 1.35em;
		}

			input[type="submit"].icon:before,
			input[type="reset"].icon:before,
			input[type="button"].icon:before,
			button.icon:before,
			.button.icon:before {
				margin-right: 0.5em;
			}

		input[type="submit"].fit,
		input[type="reset"].fit,
		input[type="button"].fit,
		button.fit,
		.button.fit {
			display: block;
			margin: 0 0 1em 0;
			width: 100%;
		}

		input[type="submit"].small,
		input[type="reset"].small,
		input[type="button"].small,
		button.small,
		.button.small {
			font-size: 0.8em;
		}

		input[type="submit"].big,
		input[type="reset"].big,
		input[type="button"].big,
		button.big,
		.button.big {
			font-size: 1.35em;
		}

		input[type="submit"].disabled, input[type="submit"]:disabled,
		input[type="reset"].disabled,
		input[type="reset"]:disabled,
		input[type="button"].disabled,
		input[type="button"]:disabled,
		button.disabled,
		button:disabled,
		.button.disabled,
		.button:disabled {
			background-color: rgba(255, 255, 255, 0.75) !important;
			box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
			color: #202024 !important;
			cursor: default;
			opacity: 0.25;
		}

		input[type="submit"].style2,
		input[type="reset"].style2,
		input[type="button"].style2,
		button.style2,
		.button.style2 {
			background-color: #7f92cf;
		}

			input[type="submit"].style2:hover,
			input[type="reset"].style2:hover,
			input[type="button"].style2:hover,
			button.style2:hover,
			.button.style2:hover {
				background-color: #92a2d6;
			}

			input[type="submit"].style2:active,
			input[type="reset"].style2:active,
			input[type="button"].style2:active,
			button.style2:active,
			.button.style2:active {
				background-color: #6c82c8;
			}

		input[type="submit"].style3,
		input[type="reset"].style3,
		input[type="button"].style3,
		button.style3,
		.button.style3 {
			background-color: #9d7ed0;
		}

			input[type="submit"].style3:hover,
			input[type="reset"].style3:hover,
			input[type="button"].style3:hover,
			button.style3:hover,
			.button.style3:hover {
				background-color: #ab91d7;
			}

			input[type="submit"].style3:active,
			input[type="reset"].style3:active,
			input[type="button"].style3:active,
			button.style3:active,
			.button.style3:active {
				background-color: #8f6bc9;
			}

		@media screen and (max-width: 480px) {

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			button,
			.button {
				padding: 0;
			}

		}

/* Poptrox */

	.poptrox-popup {
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		-ms-box-sizing: content-box;
		box-sizing: content-box;
		background: #000;
		box-shadow: 0 0.5em 3em 0 rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		padding: 2em;
		overflow: hidden;
	}

		.poptrox-popup .closer {
			-moz-transition: opacity 0.2s ease-in-out;
			-webkit-transition: opacity 0.2s ease-in-out;
			-ms-transition: opacity 0.2s ease-in-out;
			transition: opacity 0.2s ease-in-out;
			text-decoration: none;
			position: absolute;
			top: .5em;
			right: .5em;
			z-index: 11000;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
			white-space: nowrap;
			text-indent: 2em;
			overflow: hidden;
			width: 2em;
			height: 2em;
			display: block;
		}

			.poptrox-popup .closer:before {
				content: "\f00d";
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-transform: none !important;
				display: block;
				position: relative;
				text-indent: 0;
				border-radius: 100%;
				box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
				color: #ffffff !important;
				width: 2em;
				height: 2em;
				line-height: 2em;
			}

		.poptrox-popup:hover .closer {
			opacity: 0.5;
		}

		.poptrox-popup:hover .closer:hover {
			opacity: 1.0;
		}

		@media screen and (max-width: 736px) {

			.poptrox-popup {
				padding: 0;
			}

				.poptrox-popup .closer {
					display: none !important;
				}

		}

/* Banner */

	#banner {
		-moz-align-items: center;
		-webkit-align-items: center;
		-ms-align-items: center;
		align-items: center;
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		padding: 8em 4em 6em 4em;
		min-height: 80vh;
		background-image: url("../img/banner.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-top: 0;
		position: relative;
		text-align: center;
		overflow: hidden;
	}

		#banner .inner {
			text-align: center;
			position: relative;
			z-index: 2;
		}

		#banner h1 {
			color: #fff;
			font-size: 4em;
			font-weight: 700;
			
			margin: 0;
		}

			@media screen and (max-width: 980px) {

				#banner h1 {
					font-size: 5em;
				}

			}

			@media screen and (max-width: 736px) {

				#banner h1 {
					font-size: 3em;
				}

			}

		#banner p {
			color: rgba(255, 255, 255, 0.85);
			font-size: 1.5em;
			font-weight: 500;
		}

			@media screen and (max-width: 980px) {

				#banner p {
					font-size: 1.5em;
				}

			}

			@media screen and (max-width: 480px) {

				#banner p {
					font-size: 1.25em;
				}

			}

		#banner a {
			color: rgba(255, 255, 255, 0.75);
			text-decoration: none;
			border-bottom: 1px dotted;
		}

			#banner a:hover {
				color: #FFF;
				border: none;
				text-decoration: none;
			}

		#banner .more {
			background-image: url("./images/arrow.svg");
			background-position: center 1.35em;
			background-repeat: no-repeat;
			background-size: auto;
			border: 1px solid #fff;
			border-radius: 100%;
			color: rgba(255, 255, 255, 0.75);
			display: block;
			height: 4em;
			text-indent: 4em;
			overflow: hidden;
			white-space: nowrap;
			width: 4em;
			z-index: 2;
			margin: 0 auto 2em auto;
		}

			#banner .more:hover {
				background-color: rgba(255, 255, 255, 0.05);
				
				color: #FFF;
			}

		#banner video {
			-moz-transform: translateX(50%) translateY(50%);
			-webkit-transform: translateX(50%) translateY(50%);
			-ms-transform: translateX(50%) translateY(50%);
			transform: translateX(50%) translateY(50%);
			position: absolute;
			bottom: 50%;
			right: 50%;
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
			overflow: hidden;
		}

		#banner:before {
			-moz-transition: opacity 3s ease;
			-webkit-transition: opacity 3s ease;
			-ms-transition: opacity 3s ease;
			transition: opacity 3s ease;
			-moz-transition-delay: 1s;
			-webkit-transition-delay: 1s;
			-ms-transition-delay: 1s;
			transition-delay: 1s;
			content: '';
			background-color: #42424f;
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.45;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		@media screen and (max-width: 1280px) {

			#banner video {
				display: none;
			}

		}

		@media screen and (max-width: 980px) {

			#banner br {
				display: none;
			}

		}

		@media screen and (max-width: 736px) {

			#banner {
				min-height: 0;
				padding: 6em 2em 4em 2em;
			}

				#banner br {
					display: none;
				}

		}

		body.is-loading #banner:before {
			opacity: 1;
		}

/* Main */
	#beritablog {
		padding: 1em 0 1em 0;
		background: rgb(2,37,96);
		background: linear-gradient(162deg, rgba(2,37,96,0.9475140397956058) 15%, rgba(51,19,91,0.9531162806919643) 47%, rgba(2,37,96,1) 94%);
	}

	.headingberita {
		padding-bottom: 3em;
	}

	#main {
        background: rgb(2,37,96);
		background: linear-gradient(162deg, rgba(2,37,96,0.9475140397956058) 15%, rgba(51,19,91,0.9531162806919643) 47%, rgba(2,37,96,1) 94%);
		padding: 4em 0 2em 0;
	}

		@media screen and (max-width: 736px) {

			#main {
				padding: 3em 0 1em 0;
			}

		}

		#main .inner {
			width: 90%;
			max-width: 80em;
			margin: 0 auto;
		}

			@media screen and (max-width: 480px) {

				#main .inner {
					width: 95%;
				}

			}
.logosponsor {
	height: 50px;
	
}

.text-sponsor > h1 {
	font-size: 40px;
	text-align: center;
}


.imgcenter {
	
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 60%;
	height:'80px'

}


#fotercopyright {
	background-color:#010101;
	padding-top: 15px;
	padding-bottom: 15px;
	color: #fff;
}

/* Footer */

	#footer {
		padding: 4em 0 2em 0;
		background-color: #fff;
		text-align: center;
	}

		#footer .inner {
			width: 50%;
			margin: 0 auto;
		}

			@media screen and (max-width: 980px) {

				#footer .inner {
					width: 75%;
				}

			}

			@media screen and (max-width: 480px) {

				#footer .inner {
					width: 90%;
				}
				
				.text-sponsor > h1 {
					font-size: 30px;
					text-align: center;
				}
				.projecth1 {
					font-size: 35px;
				}
				
			}

		#footer .copyright {
			color: black;
			font-size: 0.9em;
			margin: 0 0 2em 0;
			padding: 0;
			text-align: center;
		}

		@media screen and (max-width: 736px) {

			#footer {
				padding: 3em 0 1em 0;
			}

        }
        
/* About */


#didukungoleh {
	background-color: #fff;
	text-align: center;
	padding-bottom: 30px;
}

.imglogo > img {
	height: 100px;
}
.text-didukung {
	padding-top: 40px;
	padding-bottom: 20px;
}
#about {
    
    background-color: #fff;
    text-align: center;
   
}

#sliderjadwal {
	background: rgb(2,37,96);
	background: linear-gradient(162deg, rgba(2,37,96,0.9475140397956058) 15%, rgba(51,19,91,0.9531162806919643) 47%, rgba(2,37,96,1) 94%);

	padding-top: 50px;
	padding-bottom: 80px;
	text-align: center;
}

.h2content {
	margin-bottom: 50px;
	font-size: 40px;
	color: white;
}


.sliderimg {
	text-align: center;
	
}


.imgsize img {
	height: 100%;
	width: 100% ;
}

.imgsize {
	margin-left: 10px;
	margin-right: 10px;
}

#about-2 {
    margin-top: 40px;
	background: rgb(2,37,96);
	background: linear-gradient(162deg, rgba(2,37,96,0.9475140397956058) 15%, rgba(51,19,91,0.9531162806919643) 47%, rgba(2,37,96,1) 94%);
    text-align: center;
	
}

#adminheader {
	background: rgb(0,0,0);
background: linear-gradient(162deg, rgba(0,0,0,0.9531162806919643) 37%, rgba(0,0,0,1) 95%);
}

#menuadmin {
	background: rgb(2,37,96);
	background: linear-gradient(162deg, rgba(2,37,96,0.9475140397956058) 15%, rgba(51,19,91,0.9531162806919643) 47%, rgba(2,37,96,1) 94%);
	height: fit-content;
	color: black !important;
	padding-bottom: 40px;
}


.logosaweran {
	height: 150px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.abot-margin {
	padding-top: 50px;
	/* height: 150px; */

}

.abot-margin h2 {
	padding-top: 30px;
}

.abot-margin2 {
	padding-top: 50px;
	height: 150px;

}

.abot-margin2 h2 {
	padding-top: 30px;
}

.abot-margin-2 {
    padding-top: 50px;
    
}

.abot-margin-3 {
    padding-top: 60px;
    padding-bottom: 30px;
}


.imglogologo {
	height: 200px;
	margin-top: 30px;
    
}

    #about  {
        width: 100%;
        margin: 0 auto;
    }

       

	@media (min-width:200px) and (max-width: 480px) {

	#about  {
		width: 100%;
		
	}
	.abot-margin > p {
		text-align: justify;
	}
	.abot-margin {
		padding-top: 0px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.abot-margin-3 {
		padding-top: 40px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.imglogologo {
		height: 200px;
		margin-bottom: 10px;
	}
	.logosaweran{
		height: 120px;
	}
	.kard {
		height: fit-content !important;
	}

	.kard2 {
		height: fit-content !important;
	}

	#ptagb {
		margin-top: 20px;
	}

	.inner h3  {
		padding-top: 0 !important;
	}

	.pb-3, .py-3 {
		padding-bottom: 0 !important;
	}

	.thumbnails {
		padding-top: 10px;
	}

	.h2content {
		font-size: 30px;
	}

	}


	@media (min-width: 481px) and (max-width: 736px) {

	#about {
	padding: 3em 0 2em 0;
	}


	.imglogologo {
	height: 250px;
	margin-top: 5px;
	}

	.kard {
		height: fit-content !important;
	}

	.kard2 {
		height: fit-content !important;
	}

	.card22 p {
		margin-top: 20px;
	}

	}

	@media (min-width: 737px) and (max-width: 1080px) {


	.imglogologo {
	height: 250px;
	margin-top: 5px;
	}

	.kard {
		height: fit-content !important;
	}
	.kard2 {
		height: fit-content !important;
	}




	}

.searcsection {
	margin-bottom: 20px;
}

.badgebtton {
	width: 150px;
	text-align: center;
	height: 50px;
	padding-top: 10px;
	margin-top: 5px;

}
/* Hiding the checkbox, but allowing it to be focused */
.badgebox
{
	opacity: 0;
	

}

.badgebox + .badge
{
    /* Move the check mark away when unchecked */
    text-indent: -999999px;
    /* Makes the badge's width stay the same checked and unchecked */
	width: 30px;
}

.badgebox:focus + .badge
{
    /* Set something to make the badge looks focused */
    /* This really depends on the application, in my case it was: */
    
    /* Adding a light border */
    box-shadow: inset 0px 0px 5px;
    /* Taking the difference out of the padding */
}

.badgebox:checked + .badge
{
    /* Move the check mark back when checked */
	text-indent: 0;
}

.filterbycat {
	height: 72px;
}

.searchinput {
	width: 150px;
}
@media screen and (max-width: 575px) {

	.filterbycat {
		height: 150px;
	}

	.filterbytext h3 {
		text-align: left;
	}
	.searchinput {
		width: 100px;
	}

}

.carboxx {
	margin-top: 40px;
	
}

.kard {
	display: flex;
	width: fit-content;
	height: 350px;
	padding: 30px;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%);
	border-radius: 15px;
	position: relative;
	box-shadow: 10px 15px #383636;
	margin-bottom: 50px;
  }
  
  .kard-face {
	position: relative;
  }

  .kard-face p {

		margin: 0;
		color: #030303;
		font-size: 14px;
		line-height: 1.8em;
		text-align: justify;
		padding-bottom: 10px;
	
  }

  .kard-face h2 {
	  padding-bottom: 10px;
	  text-align: center;
	  /* background-color: rgb(224, 21, 21); */
	  color: rgb(0, 0, 0);
	  border-radius: 5px;
	  padding-top: 5px;
	  border: solid 2px #000000;
	  font-weight: 600;
	  margin-bottom: 15px;
  }

  .kard-face ul li {
	  padding-bottom: 10px;
  }


  .kard2 {
	display: flex;
	width: fit-content;
	height: 390px;
	padding: 30px;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%);
	border-radius: 15px;
	position: relative;
	box-shadow: 10px 15px #383636;
	margin-bottom: 50px;
  }
  
  .kard2-face {
	position: relative;
  }

  .kard2-face p {

		margin: 0;
		color: #030303;
		font-size: 16px;
		line-height: 1.8em;
		text-align: justify;
		padding-bottom: 10px;
	
  }

  .kard2-face h2 {
	  padding-bottom: 10px;
	  text-align: center;
	  /* background-color: rgb(224, 21, 21); */
	  color: rgb(0, 0, 0);
	  border-radius: 5px;
	  padding-top: 5px;
	  border: solid 2px #000000;
	  font-weight: 600;
	  margin-bottom: 15px;
  }

  .kard2-face ul li {
	  padding-bottom: 10px;
  }

  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
.error-container {
  text-align: center;
  font-size: 180px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 20px 15px;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}
.error-container > span > span {
  display: inline-block;
  position: relative;
}
.error-container > span:nth-of-type(1) {
  perspective: 1000px;
  perspective-origin: 500% 50%;
  color: #F0E395;
}
.error-container > span:nth-of-type(1) > span {
  transform-origin: 50% 100% 0px;
  transform: rotateX(0);
  animation: easyoutelastic 8s infinite;
}

.error-container > span:nth-of-type(3) {
  perspective: none;
  perspective-origin: 50% 50%;
  color: #D15C95;
}
.error-container > span:nth-of-type(3) > span {
  transform-origin: 100% 100% 0px;
  transform: rotate(0deg);
  animation: rotatedrop 8s infinite;
}
@keyframes easyoutelastic {
  0% {
    transform: rotateX(0);
  }
  9% {
    transform: rotateX(210deg);
  }
  13% {
    transform: rotateX(150deg);
  }
  16% {
    transform: rotateX(200deg);
  }
  18% {
    transform: rotateX(170deg);
  }
  20% {
    transform: rotateX(180deg);
  }
  60% {
    transform: rotateX(180deg);
  }
  80% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes rotatedrop {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(30deg);
  }
  15% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(90deg);
  }
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotateX(0);
  }
}
    




/* demo stuff */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.errorpage {
  padding-top: 50px;
  background-color: rgb(15, 73, 161);
  margin: 30px 0;

}

.errorpage h1 {
  text-align: center;
  padding-top: 30px;
  color: #fff;

}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
a.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: rgb(230, 20, 20);
    padding: 10px 15px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
 
  font-size: 100%;
}


.cardshow-body {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2rem;
  font-family: sans-serif;
}
@media (max-width: 60em) {
    .cardshow-body {
    padding: 3rem;
  }
}

.gridss {
  display: grid;
  grid-gap: 2rem;
  grid-auto-rows: auto;
  align-items: start;
  
}
@media (max-width: 60em) {
  .gridss {
    grid-gap: 3rem;
  }
}
.gridss__item {
  background-color: #fff;
  border-radius: .4rem;
  overflow: hidden;
  box-shadow: 0 4rem 9rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 2rem;

}
.gridss__item:hover {
  transform: translateY(-0.5%);
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}

.cardshow__img {
  display: block;
  width: 100%;
  height: 18rem;
  object-fit: cover;
}
.cardshow__content {
  padding: 2rem 2rem;
}
.cardshow__header {
  font-size: 1.2rem;
  font-weight: 300;
  color: #0d0d0d;
  margin-bottom: 1.5rem;
}
.cardshow__text {
  font-size: 1rem;
  text-align: justify;
  color: #3d3d3d;
  margin-bottom: 2rem;
}
.cardshow__btn {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  color: #ffffff;
  background-color: #d11d1d;
  border: none;
  border-radius: .4rem;
  transition: 0.2s;
  cursor: pointer;
}
.cardshow__btn span {
  margin-left: 1rem;
  transition: 0.2s;
}
.cardshow__btn:hover, .cardshow__btn:active {
  background-color: #04194e;
}
.cardshow__btn:hover span, .cardshow__btn:active span {
  margin-left: 1.5rem;
}
